import SmoothScroll from 'smoothscroll-for-websites/SmoothScroll.js';
import Splitting from 'splitting';
import ScrollOut from "scroll-out";

const loaderFadeOutDelay = 3000;

Splitting();
function initScrollOut()
{
    let headWordSequence = 0,
        isUpdateRunning = false;
    function updateHeadWord()
    {
        let animNameToAdd = '',
            animNameToRemove = '',
            nextWord = '',
            nextDelay = 0;

        const letters = document.querySelector( "h1" ).querySelector("span.word:first-child" ).querySelectorAll( 'span.char' );

        headWordSequence++;
        switch( headWordSequence )
        {
            case 6:
                nextWord = 'YACHTS';
                for ( let wi = 0; wi < nextWord.length; wi++ )
                {
                    letters[ wi ].innerText = nextWord[ wi ];
                }
                animNameToRemove = 'leBlurOut';
                animNameToAdd = 'leBlurIn';
                nextDelay = 3000;
            break;
            case 4:
                nextWord = 'NAVAL ';
                for ( let wi = 0; wi < nextWord.length; wi++ )
                {
                    letters[ wi ].innerText = nextWord[ wi ];
                }
                animNameToRemove = 'leBlurOut';
                animNameToAdd = 'leBlurIn';
                nextDelay = 3000;
            break;
            case 2:
                nextWord = 'CRUISE';
                for ( let wi = 0; wi < nextWord.length; wi++ )
                {
                    letters[ wi ].innerText = nextWord[ wi ];
                }
                animNameToRemove = 'leBlurOut';
                animNameToAdd = 'leBlurIn';
                nextDelay = 3000;
            break;

            case 7:
                headWordSequence = 0;
            case 5:
            case 3:
            case 1:
                animNameToRemove = 'leBlurIn';
                animNameToAdd = 'leBlurOut';
                nextDelay = 500;
            default:
            case 0:
        }

        for ( let li = 0; li < letters.length; li++ )
        {
            letters[ li ].classList.remove( animNameToRemove );
            letters[ li ].classList.add( animNameToAdd );
        }

        setTimeout( updateHeadWord, nextDelay );
    }


    ScrollOut({
        targets: '[data-scroll]',
        onShown : function( elem, ctx, scrollingElement )
        {
            if ( "1" === elem.getAttribute( 'data-done') ) {
                return;
            }
            elem.setAttribute( 'data-done', "1" );

            let animName = elem.hasAttribute( 'data-animname' ) ? elem.getAttribute( 'data-animname' ) : 'leScaleYIn';
            let additionalDelay = 'leBlurIn' === animName ? 0.0 : 0;

            if ( elem.hasAttribute( 'data-splitting' ) )
            {
                if ( elem.getAttribute( 'data-splitting' ) === 'items' )
                {
                    const lis = elem.querySelectorAll( 'li' );
                    for ( let li = 0; li < lis.length; li++ )
                    {
                        lis[ li ].style.setProperty( 'animation-delay', (additionalDelay + (li * 0.1)) + 's'  );
                        lis[ li ].classList.add( 'cssanimation', animName );
                    }
                }
                else
                {
                    const letters = elem.querySelectorAll( 'span.char' );
                    for ( let li = 0; li < letters.length; li++ )
                    {
                        letters[ li ].style.setProperty( 'animation-delay', (additionalDelay + (li * 0.05)) + 's'  );
                        letters[ li ].classList.add( 'cssanimation', animName );
                    }
                    if ( false === isUpdateRunning )
                    {
                        isUpdateRunning = true;
                        setTimeout( updateHeadWord.bind( this ), 3000 );
                    }
                }
            }
            else
            {
                animName = 'fadeInBottom';
                animName =  elem.nodeName === 'P' ? 'fadeInBottom' : elem.getAttribute( 'data-animname' );
                elem.classList.add( 'cssanimation', animName );
            }
            elem.style.visibility = 'visible';
        }
    });

}
window.onload = function()
{
    window.scrollTo( 0, 0 );
    setTimeout( function()
    {
        initScrollOut();
        if ( document.querySelector( '.hero' ) ) {
            document.querySelector( 'h1' ).style.visibility = 'visible';
        }
        document.body.style.overflow = 'auto';
    }.bind( this ), loaderFadeOutDelay- 1500 );
    setTimeout( function()
    {
        document.getElementById( 'page-loader-overlay' ).remove();
    }, loaderFadeOutDelay );
};
